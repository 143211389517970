import {
  type ClientLoaderFunctionArgs,
  json,
  Link,
  useLoaderData,
  useNavigate,
} from '@remix-run/react';
import { type AxiosResponse } from 'axios';
import { useEffect, useMemo, useState } from 'react';
import { $path } from 'remix-routes';

import {
  type DtoLearnerProfileResponse,
  type DtoSingleGamePackResponse,
  type DtoStackProgressionResponse,
} from '@lp-lib/api-service-client/public';

import { useLearningAnalytics } from '../analytics/learning';
import { useUserAnalytics } from '../analytics/user';
import { RequireActivation } from '../components/Access';
import { UserAccess } from '../components/Access/UserAccess';
import { GlobalAudioDetectionProvider } from '../components/GameV2/apis/AudioDetection';
import { Overworld } from '../components/GameV2/Overworld';
import { OverworldBackground } from '../components/GameV2/overworld/OverworldBackground';
import { OverworldContinueButton } from '../components/GameV2/overworld/OverworldContinueButton';
import { OverworldDrawerManager } from '../components/GameV2/overworld/OverworldDrawer';
import { type OverworldDisplayOptions } from '../components/GameV2/overworld/types';
import { StackedSquaresIcon } from '../components/icons/StackedSquaresIcon';
import { ProvidersList } from '../components/ProvidersList';
import { UserContextProvider, useUser } from '../components/UserContext';
import { useLiveAsyncCall } from '../hooks/useAsyncCall';
import { useLiveCallback } from '../hooks/useLiveCallback';
import { apiService } from '../services/api-service';
import { OrganizerRoleUtils } from '../types';
import { type Game } from '../types/game';
import { fromDTOGamePack, fromDTOGames } from '../utils/api-dto';
import { isForbidden, isNotFound, tokenWithRedirect } from '../utils/router';
import { setAPIServiceClientSecureToken } from '../utils/setAPIClientToken';

setAPIServiceClientSecureToken();

export const clientLoader = async (action: ClientLoaderFunctionArgs) => {
  const id = action.params.id;
  if (!id) {
    throw new Error('expected stack id');
  }

  let resp: AxiosResponse<DtoStackProgressionResponse>;
  let profile: AxiosResponse<DtoLearnerProfileResponse>;
  try {
    [resp, profile] = await tokenWithRedirect(
      () =>
        Promise.all([
          apiService.progression.getMyStackProgression(id),
          apiService.learning.getMyLearnerProfile({
            enrollmentsSummary: true,
            membershipsSummary: false,
          }),
        ]),
      action.request.url,
      {
        requireAuthentication: true,
        preferRedirect: 'login',
      }
    );
  } catch (e) {
    if (isForbidden(e) || isNotFound(e)) {
      // treat both errors as a 404.
      throw json({}, { status: 404 });
    } else {
      throw e;
    }
  }

  const firstIncompleteCourseIndex = resp.data.items.findIndex(
    (course) => !course.progression?.completedAt
  );

  return {
    stackId: id,
    stackProgression: resp.data,
    profile: profile.data,
    activeCourseIndex: Math.max(firstIncompleteCourseIndex, 0),
  };
};

export function Component() {
  const data = useLoaderData<typeof clientLoader>();

  const providers = [
    <UserContextProvider useUserAnalytics={useUserAnalytics} />,
    <UserAccess allowGuests />,
    <RequireActivation />,
    <GlobalAudioDetectionProvider />,
  ];

  return (
    <ProvidersList providers={providers}>
      <StackedOverworldInternal {...data} />
    </ProvidersList>
  );
}

function StackedOverworldInternal(props: {
  stackId: string;
  stackProgression: DtoStackProgressionResponse;
  profile: DtoLearnerProfileResponse;
  activeCourseIndex: number;
}) {
  const user = useUser();
  const isOrgAdmin = OrganizerRoleUtils.isOwnerOrAdmin(user.organizer?.role);
  const [activeCourse, setActiveCourse] = useState<
    Nullable<DtoSingleGamePackResponse>
  >(() => props.stackProgression.items.at(props.activeCourseIndex));

  useEffect(() => {
    setActiveCourse(props.stackProgression.items.at(props.activeCourseIndex));
  }, [props.stackProgression, props.activeCourseIndex]);

  return (
    <div className='relative w-full h-full flex flex-col'>
      <OverworldBackground
        pack={fromDTOGamePack(activeCourse?.gamePack)}
        position='fixed'
      />

      <div className='w-full h-12.5 px-3 z-15 flex items-center justify-between text-white'>
        <OverworldDrawerManager
          initialProfile={props.profile}
          currentCourseId={props.stackId}
        />
        {isOrgAdmin && (
          <Link to={$path('/learning/admin/my-courses')}>
            <StackedSquaresIcon />
          </Link>
        )}
      </div>

      <div className='flex-1 min-h-0 overflow-auto scrollbar'>
        {props.stackProgression.items.map((v, i) => {
          return (
            <div key={i}>
              <OverworldInternal
                stackId={props.stackId}
                bundle={v}
                profile={props.profile}
                active={i === props.activeCourseIndex}
              />
            </div>
          );
        })}
        <div className='h-20' />
      </div>

      <div className='fixed bottom-5 w-full flex justify-center z-10'>
        <OverworldContinueButton
          stackId={props.stackId}
          packId={activeCourse?.gamePack.id}
          progression={activeCourse?.progression}
        />
      </div>
    </div>
  );
}

function OverworldInternal(props: {
  stackId: string;
  bundle: DtoSingleGamePackResponse;
  profile: DtoLearnerProfileResponse;
  active: boolean;
}) {
  const { gamePack, games, progression } = props.bundle;
  const user = useUser();
  const analytics = useLearningAnalytics();
  const navigate = useNavigate();

  const { call: handlePlayGame } = useLiveAsyncCall(async (game: Game) => {
    if (!progression) {
      await apiService.progression.createMyProgression(gamePack.id);
    }
    navigate(
      `/game-packs/${gamePack.id}/play/${game.id}?stack-id=${props.stackId}`
    );
  });

  const displayOptions = useMemo<OverworldDisplayOptions>(() => {
    return {
      showLpLogo: false,
      disableBg: true,
      disableEmphasis: true,
      overflow: '',
      bottomSpacing: 'h-24',
      logoPlaceholderSpacing: 'h-0',
      disableScrollLevelIntoView: !props.active,
      hideStartBounce: !props.active,
    };
  }, [props.active]);

  const handleClickGame = useLiveCallback((game: Game) => {
    analytics.trackCourseStarted({
      packId: gamePack.id,
      packName: gamePack.name,
      groupId: game.id,
      groupName: game.name,
      isCourseCreator: gamePack.uid === user.id,
    });
    handlePlayGame(game);
  });

  return (
    <Overworld
      pack={fromDTOGamePack(gamePack)}
      games={fromDTOGames(games || [])}
      progression={progression}
      onClickGame={handleClickGame}
      displayOptions={displayOptions}
    />
  );
}
